<template>
  <v-app class="background">
    <v-main>
      <section id="profile">
        <v-row no-gutters>
          <v-col>
            <v-card color="light-blue lighten-3" flat>
              <v-card-text>
                <v-row>
                  <v-col cols="3" md="3" lg="3" xl="3" class="d-flex justify-center">
                    <v-card width="100%" class="d-flex justify-center align-center" flat color="transparent">
                      <v-img min-width="70" width="20vw" max-width="300" src="../assets/logo_kedas.png"></v-img>
                    </v-card>
                  </v-col>

                  <v-col cols="9" md="9" lg="9" xl="9">
                    <v-row no-gutters class="fill-height align-center">
                      <v-col v-if="member.data && member.country && member.state">
                        <v-row no-gutters class="align-center mb-2">
                          <span id="member-name" class="white--text">
                            {{ member.data.name }}
                          </span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon right v-on="on" color="white">mdi-check-decagram</v-icon>
                            </template>
                            <span>This Member Is Verified</span>
                          </v-tooltip>
                        </v-row>
                        <v-row no-gutters class="align-center mt-4">
                          <span id="member-address" class="white--text">
                            {{ member.data.status | capitalize }} Kedas Beauty
                            {{ member.data.district }}, {{ member.state.name }},
                            {{ member.country.name }}
                          </span>
                        </v-row>
                        <v-row no-gutters class="align-center mt-4">
                          <span class="
                              font-italic
                              text-decoration-underline
                              white--text
                            ">This Member Is Verified</span>
                        </v-row>

                        <!-- <v-btn color="primary" @click="copyWebsiteId">
                          <span>Salin Website ID</span>
                        </v-btn> -->
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <section>
        <v-container>
          <v-row no-gutters class="d-flex justify-center align-center">
            <h3 class="blue-grey--text">Beli Produk Disini</h3>
          </v-row>
          <v-row v-if="member.data">
            <v-col cols="6" md="6" lg="3" xl="3" class="d-flex justify-center" v-for="(p, i) in products" :key="i">
              <v-menu offset-y bottom transition="slide-y-transition" :close-on-content-click="false">
                <template v-slot:activator="{ attrs, on }">
                  <v-card rounded="lg" min-width="125" width="45vw" max-width="500" v-on="on" v-bind="attrs">
                    <v-img class="ma-2" :src="p.img"></v-img>
                    <v-card-actions>
                      <v-menu offset-y bottom transition="slide-x-transition" :close-on-content-click="false">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn block depressed color="accent" v-bind="attrs" v-on="on">
                            <span>beli</span>
                            <v-icon right>mdi-shopping</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense nav>
                          <v-list-item v-for="link in member.data.links" :key="link.url" link
                            @click="handleOrder(link)">
                            <v-list-item-avatar tile>
                              <v-img contain :src="link.img"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                  link.app_name
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-card-actions>
                  </v-card>
                </template>
                <v-list dense nav>
                  <v-list-item v-for="link in member.data.links" :key="link.url" link @click="handleOrder(link)">
                    <v-list-item-avatar tile>
                      <v-img contain :src="link.img"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ link.app_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-main>

    <section>
      <v-row class="flex justify-end">
        <v-col cols="6" lg="4" xl="4">
          <v-img lazy-src="../assets/logo_bpom_halal.svg" src="../assets/logo_bpom_halal.svg" placeholder="bpom_halal">
          </v-img>
        </v-col>
      </v-row>
    </section>

    <!-- last section -->
    <v-footer padless class="justify-center" color="transparent">
      <v-col class="text-center" cols="12">
        <span class="blue-grey--text">{{ new Date().getFullYear() }} — </span>
        <strong class="blue-grey--text">PT. MAHAPURNA MANDIRI UTAMA</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<style scoped>
.first-section {
  background-image: url("../assets/top_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.background {
  background: rgb(238, 174, 202);
  background: linear-gradient(112deg,
      rgba(238, 174, 202, 1) 0%,
      rgba(193, 246, 252, 1) 57%,
      rgba(179, 211, 249, 1) 100%);
  background-repeat: no-repeat;
}

.p-card {
  background-image: linear-gradient(#70d8f1, #ae97e2);
}

a {
  text-decoration: none;
}

.page-footer {
  background-image: url("../assets/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.text-shadow {
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.54);
}

#member-name {
  font-size: 25px;
  font-weight: bold;
}

#member-address {
  font-size: 15px;
  font-weight: bold;
}

#sold-count {
  font-size: 10px;
}

@media screen and (min-width: 320px) {
  #member-name {
    font-size: calc(25px + 5 * ((100vw - 320px) / 680));
  }

  #member-address {
    font-size: calc(15px + 5 * ((100vw - 320px) / 680));
  }

  #sold-count {
    font-size: calc(10px + 0.5 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  #member-name {
    font-size: 50px;
  }

  #member-address {
    font-size: 24px;
  }

  #sold-count {
    font-size: 20px;
  }
}
</style>

<script>
import axios from "axios";
export default {
  data() {
    return {
      config: {
        withCredentials: false,
        headers: {
          "X-CSCAPI-KEY": process.env.VUE_APP_COUNTRY_STATE_API_KEY,
        },
      },
      member: {
        data: null,
        country: null,
        state: null,
      },
      products: [
        {
          img: require("@/assets/products/gold_jelly.png"),
          name: "Gold Jelly",
        },
        {
          img: require("@/assets/products/soap.png"),
          name: "Brightening Soap",
        },
        {
          img: require("@/assets/products/serum.png"),
          name: "Brightening Body Serum",
        },
        {
          img: require("@/assets/products/scrub.png"),
          name: "Brightening Body Serum",
        },
      ],
      links: [
        {
          id: "wa",
          name: "WhatsApp",
          img: require("@/assets/logo/new/whatsapp.svg"),
        },
        {
          id: "tokped",
          name: "Tokopedia",
          img: require("@/assets/logo/new/tokopedia.svg"),
        },
        {
          id: "bl",
          name: "Bukalapak",
          img: require("@/assets/logo/new/bukalapak.svg"),
        },
        {
          id: "shp",
          name: "Shopee",
          img: require("@/assets/logo/new/shopee.svg"),
        },
        {
          id: "lzd",
          name: "Lazada",
          img: require("@/assets/logo/new/lazada.svg"),
        },
        {
          id: "fb",
          name: "Facebook",
          img: require("@/assets/logo/new/facebook.svg"),
        },
        {
          id: "ig",
          name: "Instagram",
          img: require("@/assets/logo/new/instagram.svg"),
        },
        {
          id: "tt",
          name: "TikTok Shop",
          img: require("@/assets/logo/new/tiktok.svg"),
        },
      ],
    };
  },
  methods: {
    async getData() {
      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/member/${this.$route.query.website_id}/data`
        )
        .then((response) => {
          this.member.data = response.data;
          this.setLinks();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCountry() {
      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/countries/${this.member.data.country}`
        )
        .then((response) => {
          this.member.country = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getState() {
      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/countries/${this.member.data.country}/states/${this.member.data.state}`
        )
        .then((response) => {
          this.member.state = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setLinks() {
      let links = this.links;
      let member_link = this.member.data.links;
      for (let i = 0; i < member_link.length; i++) {
        for (let j = 0; j < links.length; j++) {
          if (member_link[i].app_name == links[j].name) {
            member_link[i]["app_code"] = links[j].id;
            member_link[i]["img"] = links[j].img;
          }
        }
      }
      //   console.log(member_link);
    },

    async handleOrder(val) {
      // console.log(val);
      //   var id = this.member.data.id;
      switch (val.app_code) {
        case "wa":
          window.open(val.url, "_blank");
          //   if (val.url) {
          //     this.sendMessage();
          //     await axios
          //       .post(
          //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
          //         {
          //           reseller_id: id,
          //           whatsapp: true,
          //         }
          //       )
          //       .then((response) => {
          //         console.log(response.data);
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   } else {
          //     this.snackbar.show = true;
          //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
          //     this.snackbar.color = "error";
          //     this.snackbar.icon = "mdi-close";
          //   }
          break;
        case "tokped":
          window.open(val.url, "_blank");
          //   if (val.url) {
          //     await axios
          //       .post(
          //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
          //         {
          //           reseller_id: id,
          //           tokopedia: true,
          //         }
          //       )
          //       .then((response) => {
          //         console.log(response.data);
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   } else {
          //     this.snackbar.show = true;
          //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
          //     this.snackbar.color = "error";
          //     this.snackbar.icon = "mdi-close";
          //   }
          break;
        case "bl":
          window.open(val.url, "_blank");
          //   if (val.url) {
          //     await axios
          //       .post(
          //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
          //         {
          //           reseller_id: id,
          //           bukalapak: true,
          //         }
          //       )
          //       .then((response) => {
          //         console.log(response.data);
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   } else {
          //     this.snackbar.show = true;
          //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
          //     this.snackbar.color = "error";
          //     this.snackbar.icon = "mdi-close";
          //   }
          break;
        case "lzd":
          window.open(val.url, "_blank");
          //   if (val.url) {
          //     await axios
          //       .post(
          //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
          //         {
          //           reseller_id: id,
          //           lazada: true,
          //         }
          //       )
          //       .then((response) => {
          //         console.log(response.data);
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   } else {
          //     this.snackbar.show = true;
          //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
          //     this.snackbar.color = "error";
          //     this.snackbar.icon = "mdi-close";
          //   }
          break;
        case "shp":
          window.open(val.url, "_blank");
          //   if (val.url) {
          //     await axios
          //       .post(
          //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
          //         {
          //           reseller_id: id,
          //           shopee: true,
          //         }
          //       )
          //       .then((response) => {
          //         console.log(response.data);
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   } else {
          //     this.snackbar.show = true;
          //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
          //     this.snackbar.color = "error";
          //     this.snackbar.icon = "mdi-close";
          //   }
          break;
        case "fb":
          window.open(val.url, "_blank");
          //   if (val.url) {
          //     await axios
          //       .post(
          //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
          //         {
          //           reseller_id: id,
          //           facebook: true,
          //         }
          //       )
          //       .then((response) => {
          //         console.log(response.data);
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   } else {
          //     this.snackbar.show = true;
          //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
          //     this.snackbar.color = "error";
          //     this.snackbar.icon = "mdi-close";
          //   }
          break;
        case "ig":
          window.open(val.url, "_blank");
          //   if (val.url) {
          //     await axios
          //       .post(
          //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
          //         {
          //           reseller_id: id,
          //           instagram: true,
          //         }
          //       )
          //       .then((response) => {
          //         console.log(response.data);
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   } else {
          //     this.snackbar.show = true;
          //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
          //     this.snackbar.color = "error";
          //     this.snackbar.icon = "mdi-close";
          //   }
          break;
        case "tt":
          window.open(val.url, "_blank");
        //   if (val.url) {
        //     await axios
        //       .post(
        //         `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions`,
        //         {
        //           reseller_id: id,
        //           tiktok: true,
        //         }
        //       )
        //       .then((response) => {
        //         console.log(response.data);
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //       });
        //   } else {
        //     this.snackbar.show = true;
        //     this.snackbar.text = "URL Tidak Ditemukan. Coba Link Lainnya";
        //     this.snackbar.color = "error";
        //     this.snackbar.icon = "mdi-close";
        //   }
        //   break;
      }
      // console.log(this.order);
    },
  },
  filters: {
    capitalize: (value) => {
      return value
        .toLowerCase()
        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
    },
  },
  mounted() {
    this.getData().then(() => {
      this.getCountry();
      this.getState();
    });
  },
};
</script>