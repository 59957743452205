var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"background"},[_c('v-main',[_c('section',{attrs:{"id":"profile"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{attrs:{"color":"light-blue lighten-3","flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"3","md":"3","lg":"3","xl":"3"}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"width":"100%","flat":"","color":"transparent"}},[_c('v-img',{attrs:{"min-width":"70","width":"20vw","max-width":"300","src":require("../assets/logo_kedas.png")}})],1)],1),_c('v-col',{attrs:{"cols":"9","md":"9","lg":"9","xl":"9"}},[_c('v-row',{staticClass:"fill-height align-center",attrs:{"no-gutters":""}},[(_vm.member.data && _vm.member.country && _vm.member.state)?_c('v-col',[_c('v-row',{staticClass:"align-center mb-2",attrs:{"no-gutters":""}},[_c('span',{staticClass:"white--text",attrs:{"id":"member-name"}},[_vm._v(" "+_vm._s(_vm.member.data.name)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":"","color":"white"}},on),[_vm._v("mdi-check-decagram")])]}}],null,false,3111946488)},[_c('span',[_vm._v("This Member Is Verified")])])],1),_c('v-row',{staticClass:"align-center mt-4",attrs:{"no-gutters":""}},[_c('span',{staticClass:"white--text",attrs:{"id":"member-address"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.member.data.status))+" Kedas Beauty "+_vm._s(_vm.member.data.district)+", "+_vm._s(_vm.member.state.name)+", "+_vm._s(_vm.member.country.name)+" ")])]),_c('v-row',{staticClass:"align-center mt-4",attrs:{"no-gutters":""}},[_c('span',{staticClass:"\n                            font-italic\n                            text-decoration-underline\n                            white--text\n                          "},[_vm._v("This Member Is Verified")])])],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1),_c('section',[_c('v-container',[_c('v-row',{staticClass:"d-flex justify-center align-center",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"blue-grey--text"},[_vm._v("Beli Produk Disini")])]),(_vm.member.data)?_c('v-row',_vm._l((_vm.products),function(p,i){return _c('v-col',{key:i,staticClass:"d-flex justify-center",attrs:{"cols":"6","md":"6","lg":"3","xl":"3"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({attrs:{"rounded":"lg","min-width":"125","width":"45vw","max-width":"500"}},'v-card',attrs,false),on),[_c('v-img',{staticClass:"ma-2",attrs:{"src":p.img}}),_c('v-card-actions',[_c('v-menu',{attrs:{"offset-y":"","bottom":"","transition":"slide-x-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","depressed":"","color":"accent"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("beli")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-shopping")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.member.data.links),function(link){return _c('v-list-item',{key:link.url,attrs:{"link":""},on:{"click":function($event){return _vm.handleOrder(link)}}},[_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","src":link.img}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(link.app_name))])],1)],1)}),1)],1)],1)],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.member.data.links),function(link){return _c('v-list-item',{key:link.url,attrs:{"link":""},on:{"click":function($event){return _vm.handleOrder(link)}}},[_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","src":link.img}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(link.app_name))])],1)],1)}),1)],1)],1)}),1):_vm._e()],1)],1)]),_c('section',[_c('v-row',{staticClass:"flex justify-end"},[_c('v-col',{attrs:{"cols":"6","lg":"4","xl":"4"}},[_c('v-img',{attrs:{"lazy-src":require("../assets/logo_bpom_halal.svg"),"src":require("../assets/logo_bpom_halal.svg"),"placeholder":"bpom_halal"}})],1)],1)],1),_c('v-footer',{staticClass:"justify-center",attrs:{"padless":"","color":"transparent"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"blue-grey--text"},[_vm._v(_vm._s(new Date().getFullYear())+" — ")]),_c('strong',{staticClass:"blue-grey--text"},[_vm._v("PT. MAHAPURNA MANDIRI UTAMA")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }